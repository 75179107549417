.OfferCarsV2-module__offerCarsSection {
  margin-bottom: 50px;
}

.OfferCarsV2-module__offerCarsSection > .OfferCarsV2-module__container {
  max-width: 1000px;
}

.OfferCarsV2-module__offerCarsSection .OfferCarsV2-module__offersBox {
  transition: 0.3s;
}

.OfferCarsV2-module__offersBox {
  display: flex;
  gap: 12px;
}
.OfferCarsV2-module__offersBox > div {
  width: calc(100% / 4 - 23px / 2);
  border-radius: 8px;
  overflow: hidden;
}

.OfferCarsV2-module__revampedContainer {
  margin-top: 40px;
}

.OfferCarsV2-module__revampedContainer > [class*="container"] {
  max-width: 100% !important;
  width: 100% !important;
}

.OfferCarsV2-module__singleBoxOffer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: solid 1px #e9e9e9;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  height: 100%;
  justify-content: flex-start;
}

.OfferCarsV2-module__singleBoxOffer > div > h3 {
  color: #154f9c;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-decoration: underline;
  padding: 30px 10px;
  text-align: center;
}

.OfferCarsV2-module__singleBoxOffer > .OfferCarsV2-module__imageContainer {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.OfferCarsV2-module__singleBoxOffer > .OfferCarsV2-module__imageContainer > img {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  right: 0;
  transition: 0.7s;
}

.OfferCarsV2-module__singleBoxOffer:hover {
  background-color: #e9e9e9;
}

.OfferCarsV2-module__singleBoxOffer:hover > div > img {
  transform: scale(1.05);
}

.OfferCarsV2-module__viewAlloffers {
  border-radius: 4px;
  padding: 15px 20px;
  font-weight: bold;
  min-width: 190px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16px;
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

@media screen and (min-width: 1025px) {

  .OfferCarsV2-module__has3Cars{
    justify-content: center;
  }
  .OfferCarsV2-module__has3Cars > div {
    width: calc(100% / 4 - 23px / 2);
  }


  .OfferCarsV2-module__offerCarsSection .OfferCarsV2-module__offersBox {
    transform: none !important;
    flex-wrap: wrap;
    /*margin: 40px 0;*/
    margin-bottom: 40px;
  }

  .OfferCarsV2-module__offersBox:not(.OfferCarsV2-module__showAllOfff) > div:nth-child(n + 5) {
    display: none;
  }

  .OfferCarsV2-module__singleBoxOffer > .OfferCarsV2-module__imageContainer {
    height: 227px;
  }
}

.OfferCarsV2-module__revampedMobileH {
  display: block;
}

@media screen and (max-width: 1024px) {
  .OfferCarsV2-module__m-show + .OfferCarsV2-module__offerCarsSection {
    margin-top: 80px;
  }

  .OfferCarsV2-module__offerCarsSection {
    margin-bottom: 40px;
    overflow: hidden;
  }

  .OfferCarsV2-module__offerCarsSection .OfferCarsV2-module__offersBox {
    transform: translate(-55.8906px, 0px);
  }

  .OfferCarsV2-module__offersBox > div {
    min-width: 183px;
  }

  .OfferCarsV2-module__revampedContainer {
    margin-bottom: 0 !important;
    margin-top: 40px !important;
  }

  .OfferCarsV2-module__revampedMobileH {
    font-size: 20px !important;
    line-height: 32px !important;
    text-align: start !important;
    padding-bottom: 0 !important;
  }

  .OfferCarsV2-module__revampedMobileH::after {
    display: none;
  }

  .OfferCarsV2-module__revampedMobileBoxShap {

    padding-bottom: 10px !important;
  }

  .OfferCarsV2-module__revampedMobileBoxShap .OfferCarsV2-module__singleBoxOffer > .OfferCarsV2-module__imageContainer {
    max-height: 100% !important;
    min-height: 170px;
  }

  .OfferCarsV2-module__revampedMobileBoxShap img {
    max-width: 100%;
  }

  .OfferCarsV2-module__normalScrollMob {
    transform: translate(0, 0) !important;
    position: static !important;
    max-width: 100% !important;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 16px !important;
  }

  .OfferCarsV2-module__singleBoxOffer > div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 72px;
    width: 100%;
    height: 100%;
  }

  .OfferCarsV2-module__singleBoxOffer > div > h3 {
    font-size: 12px;
    text-decoration: none;
    padding: 12px 10px;
    font-weight: 700;
  }

  .OfferCarsV2-module__viewAlloffers {
    display: none;
  }
  .OfferCarsV2-module__showMoreMob {
    min-width: 138px;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }

  .OfferCarsV2-module__showMoreMob > strong {
    color: #154f9c;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
  }

  .OfferCarsV2-module__showMoreMob > i {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M14.7075 16.2875L10.8275 12.4075L14.7075 8.5275C15.0975 8.1375 15.0975 7.5075 14.7075 7.1175C14.3175 6.7275 13.6875 6.7275 13.2975 7.1175L8.7075 11.7075C8.3175 12.0975 8.3175 12.7275 8.7075 13.1175L13.2975 17.7075C13.6875 18.0975 14.3175 18.0975 14.7075 17.7075C15.0875 17.3175 15.0975 16.6775 14.7075 16.2875Z" fill="%23154F9C"/></svg>');
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
